import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons'

export default ({
  colorCombo,
  selectLeft,
  selectRight,
  selected,
  start,
  stop,
  isFeeding
}) => {
  const boobStyles = {
    backgroundColor: colorCombo[0],
    borderColor: colorCombo[1]
  }
  const nippleStyles = {
    backgroundColor: colorCombo[1]
  }

  return (
    <>
      <div id="boobs">
        <button
          aria-label="Left boob"
          style={boobStyles}
          className={selected.left ? 'selected' : ''}
          onClick={selectLeft}
        >
          <div style={nippleStyles} className="nipple"></div>
        </button>
        <button
          aria-label="Right boob"
          style={boobStyles}
          className={selected.right ? 'selected' : ''}
          onClick={selectRight}
        >
          <div style={nippleStyles} className="nipple"></div>
        </button>
      </div>
      {isFeeding ? (
        <button aria-label="Stop feed" className="start-stop" onClick={stop}>
          <div>
            <FontAwesomeIcon icon={faStop} />
          </div>
          Stop feed
        </button>
      ) : (
        <button aria-label="Start feed" className="start-stop" onClick={start}>
          <div>
            <FontAwesomeIcon icon={faPlay} />
          </div>
          Start feed
        </button>
      )}
    </>
  )
}
