import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlay,
  faAngleDown,
  faAngleUp,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import './TimeTable.css'

const duration = (start, end) => {
  const todate = new Date(end)
  const fromdate = new Date(start)
  let diff = todate - fromdate
  const divideBy = { w: 604800000, d: 86400000, h: 3600000, m: 60000, s: 1000 }
  const hours = Math.floor(diff / divideBy['h'])
  diff -= hours * divideBy['h']
  const mins = Math.floor(diff / divideBy['m'])

  return `${
    hours > 0 ? `${hours} hour${hours === 1 ? ' ' : 's '}` : ''
  }${`${mins} min${mins === 1 ? ' ' : 's '}`}`
}

const since = time => `${duration(time, Date.now())} since last feed`

const averageDuration = times => {
  const durations = times
    .filter(t => t.start && t.stop)
    .map(t => t.stop - t.start)
  const avg = durations.reduce((s, t) => s + t, 0) / durations.length
  return `Average feed duration: ${duration(0, avg)}`
}
const averageGap = times => {
  const gaps = times
    .filter((t, i) => t.start && i < times.length - 1)
    .map((t, i) => t.start - times[i + 1].start)
  const avg = gaps.reduce((s, t) => s + t, 0) / gaps.length
  return `Average time between feeds: ${duration(0, avg)}`
}

const THead = ({ showMoreStats, setShowMoreStats, times }) => (
  <thead>
    <tr>
      <th className={showMoreStats ? 'expanded' : ''}>
        <button onClick={() => setShowMoreStats(!showMoreStats)}>
          {(times.filter(t => t.start && t.stop).length > 0 ||
            times.length > 1) && (
            <FontAwesomeIcon
              icon={showMoreStats ? faAngleUp : faAngleDown}
              size="lg"
            />
          )}
        </button>
        <div className="stats">
          <p>{since(times[0].start)}</p>
          {times.filter(t => t.start && t.stop).length > 0 && (
            <p>{averageDuration(times)}</p>
          )}
          {times.length > 1 && <p>{averageGap(times)}</p>}
        </div>
      </th>
    </tr>
  </thead>
)

const feedRowText = feedObj =>
  `${new Date(feedObj.start).toLocaleTimeString()} -${' '}${
    feedObj.stop ? duration(feedObj.start, feedObj.stop) : 'Still going!'
  }`

const FeedRow = ({
  feed,
  handleDeleteClick,
  handleResumeClick,
  showResume
}) => (
  <tr>
    <td>
      <p>{feedRowText(feed)}</p>
      <div className="icon-buttons">
        {showResume && (
          <button
            aria-label="Resume feed"
            className="icon resume left"
            onClick={handleResumeClick}
          >
            <FontAwesomeIcon icon={faPlay} />
          </button>
        )}
        <button
          aria-label="Delete feed"
          className={`icon delete ${showResume ? 'right' : ''}`}
          onClick={handleDeleteClick}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
    </td>
  </tr>
)

export default ({ times, deleteFeed, resumeLatestFeed }) => {
  const [showMoreStats, setShowMoreStats] = useState(false)

  return (
    <div className="time-table-holder">
      <table className="times">
        <THead
          showMoreStats={showMoreStats}
          setShowMoreStats={setShowMoreStats}
          times={times}
        />
        <tbody>
          {times.map((time, i) => (
            <FeedRow
              key={i}
              showResume={i === 0 && time.stop}
              feed={time}
              handleResumeClick={resumeLatestFeed}
              handleDeleteClick={() => deleteFeed(i, feedRowText(time))}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}
