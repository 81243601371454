import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons'
import './ColorSelector.css'
import { skinColors } from './colors'

const ColorSelector = ({ changeColor, selectedIndex, clear }) => {
  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  return (
    <div className={'color-selector' + (isOpen ? ' open' : ' closed')}>
      <button
        aria-label={isOpen ? 'Close options' : 'Open options'}
        className={isOpen ? 'close' : 'open'}
        onClick={isOpen ? close : open}
      >
        <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} size="lg" />
        Options
        <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleUp} size="lg" />
      </button>
      <div className="colors">
        <p style={{ width: '100%', margin: '0.5rem 0 0 0.5rem' }}>
          Boob colour
        </p>
        {skinColors.map((colorCombo, i) => {
          return (
            <button
              key={i}
              aria-label="Boob colour option"
              onClick={() => changeColor(colorCombo)}
              className={selectedIndex === i ? 'selected' : ''}
            >
              <div style={{ backgroundColor: colorCombo[0] }}></div>
            </button>
          )
        })}
        <button
          aria-label="Clear data"
          onClick={clear}
          className="clear"
        >
          Clear feeding times
        </button>
      </div>
    </div>
  )
}

export default ColorSelector
