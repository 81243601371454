import React, { useState } from 'react'
import './App.css'
import ColorSelector from './ColorSelector'
import { skinColors } from './colors'
import Title from './Title'
import BoobButtons from './BoobButtons'
import TimeTable from './TimeTable'
import useInterval from './useInterval'

export default () => {
  const [selected, setSelected] = useState(
    localStorage.selected
      ? JSON.parse(localStorage.selected)
      : { left: false, right: false }
  )
  const [colorCombo, setColorCombo] = useState(
    localStorage.colorCombo
      ? JSON.parse(localStorage.colorCombo)
      : skinColors[0]
  )
  const [times, setTimes] = useState(
    localStorage.times ? JSON.parse(localStorage.times) : []
  )

  useInterval(() => {
    setTimes([...times])
  }, 15 * 1000)

  const updateTimes = newTimes => {
    localStorage.setItem('times', JSON.stringify(newTimes))
    setTimes(newTimes)
  }

  const addTime = () => {
    updateTimes([{ start: Date.now() }, ...times])
  }

  const stopMostRecentFeed = () => {
    times[0].stop = Date.now()
    updateTimes([...times])
  }

  const updateSelected = newSelected => {
    localStorage.setItem('selected', JSON.stringify(newSelected))
    setSelected(newSelected)
    // if (newSelected.left || newSelected.right)
    //   addTime(newSelected.left ? 'left' : 'right')
  }
  const changeColor = newColorCombo => {
    localStorage.setItem('colorCombo', JSON.stringify(newColorCombo))
    setColorCombo(newColorCombo)
  }

  const selectLeft = () =>
    updateSelected({ left: !selected.left, right: false })
  const selectRight = () =>
    updateSelected({ left: false, right: !selected.right })

  const clear = () => {
    if (confirm('Really clear all stored feeding times?')) {
      updateSelected({ left: false, right: false })
      updateTimes([])
    }
  }

  const deleteFeed = (feedIndex, feedStr) => {
    if (confirm(`${feedStr}\nReally delete this feed?`)) {
      updateTimes(times.filter((t, i) => i !== feedIndex))
    }
  }

  const resumeLatestFeed = () => {
    updateTimes(
      times.map((t, i) => {
        if (i === 0) delete t.stop
        return t
      })
    )
  }

  return (
    <main>
      <Title />
      <BoobButtons
        selectLeft={selectLeft}
        selectRight={selectRight}
        colorCombo={colorCombo}
        selected={selected}
        start={addTime}
        stop={stopMostRecentFeed}
        isFeeding={
          times.length > 0 &&
          times[0].start != undefined &&
          times[0].stop == undefined
        }
      />
      {times.length > 0 && (
        <TimeTable
          times={times}
          deleteFeed={deleteFeed}
          resumeLatestFeed={resumeLatestFeed}
        />
      )}
      <ColorSelector
        selectedIndex={skinColors
          .map(c => JSON.stringify(c))
          .indexOf(JSON.stringify(colorCombo))}
        changeColor={changeColor}
        clear={clear}
      />
    </main>
  )
}
